

<template>
  <div class="rollCallPage df">
    <div class="studentList felxdc" v-show="ShowStudentList">
      <el-table
        ref="multipleTable"
        :data="groupItem.rollGroupUsers"
        :height="tableHeight"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        :header-cell-style="tableHeader"
      >
        <el-table-column label="编号" align="center" type="index" width="100px" />
        <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
      </el-table>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      :height="tableHeight"
      size="small"
      tooltip-effect="dark"
      style="width: 100%"
      stripe
      :header-cell-style="tableHeader"
    >
      <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
      <el-table-column label="课件名称" align="center" show-overflow-tooltip prop="catalogName" />
      <el-table-column label="课件时长" align="center" show-overflow-tooltip prop="lastTime" />
      <el-table-column label="点名设置" align="center" width="480px">
        <div slot-scope="scope" class="flexcc">
          <span style="width:80px">0分0秒</span>
          <el-slider
            style="width:100%;margin:0 15px;height:50px;"
            v-model="scope.row.range"
            :format-tooltip="tooltip"
            :show-tooltip="showtooltip"
            :disabled="sliderDisabled"
            range
            :marks="scope.row.marks"
            :min="0"
            :max="scope.row.kpointDuration"
            @change="(val)=>{sliderChange(val,scope.row.kpointId)}"
            @input="sliderInput"
          ></el-slider>
          <span style="min-width:80px">{{scope.row.lastTime}}</span>
        </div>
      </el-table-column>
      <Empty slot="empty" />
    </el-table>
    <el-dialog title="快速设置" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="groupForm" style="width:100%">
        <el-form-item label="点名次数：" label-width="100px">
          <div class="df">
            <el-input v-model="groupForm.count"></el-input>
            <span style="margin-left:5px">次</span>
          </div>
        </el-form-item>
        <el-form-item label="时间间隔：" label-width="100px">
          <div class="df">
            <el-input v-model="groupForm.time"></el-input>
            <span style="margin-left:5px">分</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";

export default {
  name: "rollCallPage",
  mixins: [List],
  components: {
    Empty
  },
  data() {
    return {
      dialogFormVisible: false,
      tablelist: [], // 右侧列表
      value: [],
      groupForm: {
        count: "",
        time: ""
      },
      copyList: [],
      allList: [],
      interval: null,
      oldlength: 0,
      datalength: 20,
      showtooltip: false // 滑块是否显示tooltip  //滑动时显示，松开鼠标隐藏
    };
  },
  props: {
    Height: {
      type: Number,
      default: function() {
        return 1;
      }
    },
    courseName: {
      type: String
    },
    ShowStudentList: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    // 数据
    groupItem: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 是否禁用 slider
    sliderDisabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  created() {
    // this.tableHeight = this.Height ? this.Height : this.tableHeight;
    if (this.Height > 2) {
      this.tableHeight = 400 + "";
    } else if (this.Height == 1) {
      this.tableHeight = this.tableHeight;
    } else {
      this.tableHeight = (0.98 / this.Height).toFixed(2) * 500 + "";
    }
    if (this.$route.query.stu != "view") {
      this.getTableData();
    } else {
      this.handleData(this.groupItem.rollGroupRules);
    }
  },
  computed: {},
  methods: {
    // 父组件触发的弹窗
    open() {
      this.dialogFormVisible = true;
    },
    // 保存事件
    doAjax() {
      let ruleData = this.tableData.map(el => {
        return {
          endDot: el.range[1] || el.kpointDuration,
          groupRuleId: el.groupRuleId || null,
          kpointDuration: el.kpointDuration,
          kpointId: el.kpointId,
          startDot: el.range[0] || 0,
          projectCourseId: el.projectCourseId,
          catalogName: el.catalogName
        };
      });
      const params = {
        groupId: this.$route.query.groupId,
        projectId: this.$route.query.projectId,
        ruleData
      };
      this.$post("/biz/roll/group/rule/updateDot", params).then(res => {
        if (res.status == 0) {
          this.$message({
            type: "success",
            message: "保存成功"
          });
          this.$router.back();
        }
      });
    },
    // 滑块提示文字
    tooltip(val) {
      return this.formateSeconds(val);
    },
    // row 进来时单独调
    getTableData() {
      this.$post("/biz/roll/group/rule/queryGroupRule", {
        groupId: this.$route.query.groupId,
        projectId: this.$route.query.projectId
      }).then(res => {
        if (res.status == 0) {
          let data = res.data || [];
          // copy 的数据
          this.copyList = data.map(el => {
            return {
              ...el,
              catalogName: el.catalogName || el.outlineName,
              range: [0, el.kpointDuration],
              lastTime: this.formateSeconds(el.kpointDuration)
            };
          });
          if (data.length < this.datalength) {
            this.tableData = data.map(el => {
              return {
                ...el,
                catalogName: el.catalogName || el.outlineName,
                range: [el.startDot || 0, el.endDot || el.kpointDuration],
                lastTime: this.formateSeconds(el.kpointDuration),
                marks: {
                  [el.startDot]: this.formateSeconds(el.startDot),
                  [el.endDot]: this.formateSeconds(el.endDot)
                }
              };
            });
          } else {
            this.interval = setInterval(() => {
              this.intervalHandleData(data);
            }, 20);
          }
          // // 展示的数据
          // this.tableData = data.map(el => {
          //   return {
          //     ...el,
          //     range: [el.startDot || 0, el.endDot || el.kpointDuration],
          //     lastTime: this.formateSeconds(el.kpointDuration),
          //     marks: {
          //       [el.startDot]: this.formateSeconds(el.startDot),
          //       [el.endDot]: this.formateSeconds(el.endDot)
          //     }
          //   };
          // });
        }
        // this.tableData = res.data;
      });
    },
    // 延时加载
    intervalHandleData(data) {
      if (this.datalength <= data.length) {
        let newlength = this.datalength + 1;
        // console.log(newlength, "n");
        // console.log(this.oldlength, "o");
        // console.log(this.datalength, "d");
        let list = [];
        list = data.slice(this.oldlength, this.datalength);

        this.allList = [...this.allList, ...list];
        this.tableData = this.allList.map(el => {
          return {
            ...el,
            catalogName: el.catalogName || el.outlineName,
            range: [el.startDot || 0, el.endDot || el.kpointDuration],
            lastTime: this.formateSeconds(el.kpointDuration),
            marks: {
              [el.startDot]: this.formateSeconds(el.startDot),
              [el.endDot]: this.formateSeconds(el.endDot)
            }
          };
        });
        // console.log(this.allList);
        this.datalength = newlength - 1;
        this.oldlength = newlength - 1;
        this.datalength += 5;
        if (newlength > data.length) {
          clearInterval(this.interval);
        }
      }
    },
    // 处理数据
    handleData(data) {
      const list = this.groupItem.rollGroupRules || data;
      if (list.length < this.datalength) {
        this.tableData = (list || []).map(el => {
          return {
            ...el,
            catalogName: el.catalogName || el.outlineName,
            range: [el.startDot || 0, el.endDot || el.kpointDuration],
            lastTime: this.formateSeconds(el.kpointDuration),
            marks: {
              [el.startDot]: this.formateSeconds(el.startDot),
              [el.endDot]: this.formateSeconds(el.endDot)
            }
          };
        });
      } else {
        this.interval = setInterval(() => {
          this.intervalHandleData(list);
        }, 20);
      }
    },
    checkRollTime(val, kpointId) {
      this.showtooltip = false;
      if (val[1] - val[0] <= 10) {
        this.$message({
          type: "warning",
          message: "开始与结束的时间间隔须10秒以上"
        });
        return false;
      }
      return true;
    },
    // 单个滑块事件
    sliderChange(val, kpointId) {
      this.showtooltip = false;
      if (!this.checkRollTime(val, kpointId)) {
        this.tableData = this.tableData.map(el => {
          if (el.kpointId == kpointId) {
            return {
              ...el,
              range: [el.startDot || 0, el.endDot || el.kpointDuration],
              marks: {
                [el.startDot]: this.formateSeconds(el.startDot),
                [el.endDot]: this.formateSeconds(el.endDot)
              }
            };
          } else {
            return el;
          }
        });
        return;
      }
      this.tableData = this.tableData.map(el => {
        if (el.kpointId == kpointId) {
          return {
            ...el,
            marks: {
              [val[0]]: this.formateSeconds(val[0]),
              [val[1]]: this.formateSeconds(val[1])
            }
          };
        } else {
          return el;
        }
      });
    },
    // 滑块 滑动显示tooltip
    sliderInput() {
      this.showtooltip = true;
    },
    // 弹窗确定
    doOk() {
      let count = Number(this.groupForm.count); //次数
      let time = this.groupForm.time * 60; // 时间间隔 秒0
      let isContinue = true;
      const reg = /^[1-9]?\d*$/;
      if (!count) {
        this.$message({
          type: "warning",
          message: "点名次数必填"
        });
        return;
      }
      if (count && !reg.test(count)) {
        this.$message({
          type: "warning",
          message: "点名次数应为整数"
        });
        return;
      }
      if (!time) {
        this.$message({
          type: "warning",
          message: "时间间隔必填"
        });
        return;
      }
      if (time && !reg.test(time)) {
        this.$message({
          type: "warning",
          message: "时间间隔应为整数"
        });
        return;
      }
      if (count > this.tableData.length) {
        this.$message({
          type: "warning",
          message: "点名次数不能超过课件数量"
        });
        return;
      }
      if (count && count <= 0) {
        this.$message({
          type: "warning",
          message: "点名次数不能小于0"
        });
        return;
      }
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].kpointDuration < time) {
          isContinue = false;
          break;
        }
      }
      if (!isContinue) {
        this.$message({
          type: "warning",
          message: "点名时间间隔应小于最小视频时长"
        });
        return;
      }
      let newList = [];
      this.copyList.forEach(item => {
        newList.push(Object.assign({}, item));
      });
      let indexArr = []; //页面数据的索引数组
      for (let i = 0; i < newList.length; i++) {
        indexArr.push(i);
      }
      // 把索引数组随机打乱
      indexArr.sort(() => {
        return 0.5 - Math.random();
      });
      // if (count == newList.length) {
      //   for (let i = 0; i < count; i++) {
      //     let randomTime = Math.floor(Math.random() * time);
      //     newList.forEach(el => {
      //       el.range = [randomTime, randomTime + time];
      //     });
      //   }
      // } else {
      for (let i = 0; i < count; i++) {
        // let random = Math.floor(Math.random() * newList.length);
        // 随机开始时间
        let randomTime = Math.floor(
          Math.random() * (newList[indexArr[i]].kpointDuration - time)
        );
        newList[indexArr[i]].range = [randomTime, randomTime + time];
        newList[indexArr[i]].marks = {
          [randomTime]: this.formateSeconds(randomTime),
          [randomTime + time]: this.formateSeconds(randomTime + time)
        };
      }
      // }
      this.dialogFormVisible = false;
      this.tableData = newList;
      this.groupForm.count = "";
      this.groupForm.time = "";
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${
        h > 0 ? h.toString().padStart(2, "0") + "时" : ""
      }${min.toString().padStart(2, "0")}分${secondTime
        .toString()
        .padStart(2, "0")}秒`;
      return result;
    }
  }
};
</script>

<style lang="less">
.rollCallPage {
  .studentList {
    width: 20%;
    margin-right: 20px;
    .top {
      line-height: 40px;
      background-color: #5c6be8;
      color: #fff;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
    .list {
      width: 100%;
      background-color: #f2f7fd;
      .list_item {
        padding: 10px 0;
        span {
          width: 5rem;
          text-align: center;
        }
      }
    }
  }
  .el-slider__bar,
  .el-slider__runway.disabled .el-slider__bar {
    background-color: #5c6be8 !important;
  }
  .el-slider__bar,
  .el-slider__runway {
    height: 8px;
  }
  .el-slider__marks {
    .el-slider__marks-text {
      font-size: 12px;
    }
    .el-slider__marks-text:first-child {
      transform: translateX(-100%);
    }
    .el-slider__marks-text:last-child {
      transform: translateX(0);
      width: 60px;
    }
  }
  .el-slider__button {
    height: 6px;
    width: 6px;
    border: 0;
  }
  .el-slider__button-wrapper {
    top: -14px;
  }
}
</style>