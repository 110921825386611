<template>
  <div class="framePage-scroll" style="height:100%">
    <div class="ovy-a">
      <div style="display: flex; justify-content: space-between;align-items: flex-end;">
        <h2>课程名称：{{courseName}}</h2>
         <!-- <div class="sjzStyle" v-if="rollType=='20' && stus!= 'details'">
            <span class="listLabel">每课件随机点名次数:</span>
            <el-input
              class="listInput"
              v-model="randomRollCall"
              size="small"
              clearable
              disabled
              v-if="rollNumberEdit"
            />
            <el-input
              v-if="!rollNumberEdit"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              class="listInput"
              v-model="rollNumberNew"
              placeholder="请输入每课件点名次数"
              size="small"
              clearable
            />
            <i
              class="el-icon-edit"
              @click.stop="edit"
              style="margin-right: 10px;cursor: pointer"
              v-if="rollNumberEdit"
            ></i>
            <el-button
              class="bgc-bv"
              round
              size="small"
              v-if="!rollNumberEdit"
              @click="saveRollNumber"
            >保存</el-button>
            <el-button
              class="bgc-bv"
              round
              size="small"
              v-if="!rollNumberEdit"
              @click="rollNumberEdit = true"
            >取消</el-button>
          </div> -->
          <!-- <div>
            <span>每课件随机点名次数：{{randomRollCall}}</span>
          </div> -->
      </div>
      <el-divider></el-divider>
      <templete v-if="open">
        <div v-for="(el,index) in groups" :key="index">
          <div class="table_item">
            <div class="df" style="align-items:flex-end;font-size:16px;padding:5px 0;">
              <h3 style="display:inline-block">{{el.eduRollGroup.groupName}}</h3>
              （共{{el.eduRollGroup.userNum}}名学员）
            </div>
            <RollCallPage
              ref="RollCallPage"
              :ShowStudentList="true"
              :Height="groups.length"
              :groupItem="el"
              :sliderDisabled="true"
              v-if="open"
            ></RollCallPage>
          </div>
        </div>
      </templete>
      <div style="width: 100%; height: 500px;text-align: center;" v-else>
        <div class="empty-seat">
          <div class="empty-img flexdc">
            <img src="../../../assets/empty.png" />
            <span>点名未配置</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import RollCallPage from "@/components/Rollcallpage.vue";
import Empty from "@/components/Empty.vue";
export default {
  components: {
    RollCallPage
    // Empty
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      projectId: "",
      open: false,
      groups: [], // 分组
      oldlength: 0, // 定时用
      datalength: 2,
      intervavl: null,
      randomRollCall: 0, //点名次数
      rollNumberNew: 0, //点名次数新值
      rollNumberEdit: true,
    };
  },
  props: {
    rollType: {
      type: String
    },
    stus:{
       type: String
    }
  },
  created() {
    this.getDta();
  },
  mounted() {
    //  this.$nextTick(() => {
    //     this.$refs.tree.setCheckedNodes(this.data);
    //   });
  },
  watch: {
    rollType: function(nval, oval) {
      this.groups = [];
      this.courseName = "";
      this.oldlength = 0;
      this.datalength = 2;
      this.getDta();
    },
    stus: function(nval, oval) {
      console.log(nval,oval);
      // this.groups = [];
      // this.courseName = "";
      // this.oldlength = 0;
      // this.datalength = 2;
      // this.getDta();
    }
  },
  methods: {
    getDta() {
      if (this.rollType) {
        this.$post("/biz/roll/group/projectRollCallViewByRollType", {
          projectId: this.$route.query.projectId,
          rollType: this.rollType
        })
          .then(ret => {
            if (ret.status == 0) {
              const data = ret.data || {};
              if (data.groups.length > 0) {
                this.open = true;
              } else {
                this.open = false;
              }

              this.courseName = data.courseName;
              this.failureNum = data.failureNum;

              this.groups = data.groups;
              this.randomRollCall = data.randomRollCall || 0;
            }
          })
          .catch(err => {
            return;
          });
      }
    },
    handleData(data) {
      if (this.datalength <= data.length) {
        let list = [];
        let newlength = this.datalength + 1;
        list = data.slice(this.oldlength, this.datalength);
        this.groups = [...this.groups, ...list];
        this.oldlength = newlength - 1;
        this.datalength = newlength - 1;
        this.datalength++;
        if (newlength > data.length) {
          clearInterval(this.intervavl);
        }
      }
    },
      //随机次数保存
    saveRollNumber() {
      if (this.rollNumberNew) {
        if (
          Number(this.rollNumberNew) >= 0 &&
          Number(this.rollNumberNew) < 20
        ) {
          this.$post("/sjz/project/setProjectRandomRoll", {
            randomRollCall: this.rollNumberNew,
            projectId: this.$route.query.projectId,
          }).then(res => {
            this.rollNumber = this.rollNumberNew;
            this.rollNumberEdit = true;
            this.getDta()
          });
        } else {
          this.$message({
            type: "warning",
            message: "随机点名次数必须是大于或等于0且小于20的正整数"
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "随机点名次数必须是大于或等于0的正整数"
        });
      }
    },
     //随机次数编辑
    edit() {
      this.rollNumberNew = this.randomRollCall;
      this.rollNumberEdit = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 32;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.el-input-number__decrease {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.empty-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
  img {
    width: 10.9375rem;
  }
}
.el-tree {
  width: 100%;
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  font-size: 14px;
}

.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 23.85rem;
  font-size: 14px;
}
.el-table .warning-row {
  background: red;
}
.el-table .success-row {
  background: #ffffff;
}
</style>
<style lang="less" scoped>
//分割线
.el-divider.el-divider--horizontal {
  margin: 16px 0;
}
.el-divider {
  background-color: #f5f5f5;
  position: relative;
}
.sjzStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  .listLabel {
    width: 90x;
    text-align: right;
    padding-left: 15px;
  }
  .listInput {
    width: 300px;
    margin: 0 10px;
  }
}
</style>
